import * as React from 'react'
import ReactSVG from 'react-svg'
import { Button, canvas } from '@workday/canvas-kit-react'
import { Link, withPrefix } from 'gatsby'
import NWConstants from '../../utils/constants'

const demoImagePath = withPrefix('/images/HeroGraphic.png')

export interface HeroProps {location: object}

class Hero extends React.Component<HeroProps, HeroState> {
	constructor(props: HeroProps) {
		super(props)
	}

	render() {
		const searchParam = this.props.location.search || ''
		const linkTo = `/install${searchParam}`

		return (
			<div className="hero-bk">
				<div className="hero-wrapper">
					<div className="hero-landing-container wdc-marketing-container">
						<div className="hero-landing-content">
							<h1>Workday + Slack</h1>
							<p>Leverage the power of Workday easier than ever using the tools you depend on.</p>
							<div className="button-container">
								<Link to={linkTo}>
										<Button buttonType={Button.Types.Primary}>Add to Slack</Button>
								</Link>
								<a target="_blank" href={NWConstants.COMMUNITY_URL} className="secondary-button"><Button buttonType={Button.Types.Secondary}>Community</Button></a>
							</div>
						</div>
						<div className="hero-landing-image">
							<img src={demoImagePath} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Hero
