import * as React from 'react'
import { Link, withPrefix } from 'gatsby'
import { Button, canvas } from '@workday/canvas-kit-react'
import Hero from './hero'
import ReactSVG from 'react-svg'
import NWConstants from '../../utils/constants'

interface HomeProps {location: object}

class Home extends React.Component<HomeProps> {
	constructor(props: HomeProps) {
		super(props)
	}

	render() {
		const rocketPath = withPrefix('/images/Rocket.png')
		const vectorPath = withPrefix('/icons/Vector.svg')
		return (
			<div>
				<Hero location={this.props.location}/>
				<div className="wdc-color-bk-blue">
					<h5 className="feature-heading"><img src={rocketPath} /><strong> OUR FEATURES</strong></h5>
					<div className="hero-section light-bulb-section">
						<div className="light-bulb-content">
							<div className="feature">
								<h6>Upload Expenses</h6>
								<p>Conveniently enter receipts and other quick expenses using Slack, to enable timely and accurate expense entry on the go.</p>
							</div>
							<div className="feature">
								<h6>Streamline Feedback</h6>
								<p>Submit Anytime Feedback for coworkers directly from Slack, to drive a recognition rich culture.</p>
							</div>
							<div className="feature">
								<h6>Lookup Coworkers</h6>
								<p>Easily lookup a person’s Workday details and share with others, to facilitate more effective communication and decision making.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="wdc-color-bk-gray border-wrap additional-resources">
					<h5 className="feature-heading"><strong>ADDITIONAL RESOURCES</strong></h5>
					<div className="additional-resources-container">
						<a href={NWConstants.COMMUNITY_URL}>
							<div className="additional-resource">
								<p className="additional-resource-primary-text">Learn about configuration and installation</p>
								<p>Visit our community page</p>
								<span className="svg-container">
									<ReactSVG src={vectorPath} />
								</span>
							</div>
						</a>
						<a href={NWConstants.OPENNESS_BLOG_URL}>
							<div className="additional-resource">
								<p className="additional-resource-primary-text">Openness at Workday</p>
								<p>Read the blog here</p>
								<span className="svg-container">
									<ReactSVG src={vectorPath} />
								</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default Home
